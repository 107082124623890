@font-face {
  font-family: 'circular-black';
  src: url('./../../assets/fonts/CircularStdBlack.woff2') format('woff2'),
       url('./../../assets/fonts/CircularStdBlack.woff') format('woff');
}

@font-face {
  font-family: 'circular-bold';
  src: url('./../../assets/fonts/CircularStdBold.woff2') format('woff2'),
       url('./../../assets/fonts/CircularStdBold.woff') format('woff');
}

@font-face {
  font-family: 'circular-book';
  src: url('./../../assets/fonts/CircularStdBook.woff2') format('woff2'),
       url('./../../assets/fonts/CircularStdBook.woff') format('woff');
}

@font-face {
  font-family: 'value-serif';
  src: url('./../../assets/fonts/valueserifbold.woff2') format('woff2'),
       url('./../../assets/fonts/valueserifbold.woff') format('woff');
}

::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
}


body {
  opacity: 0;
  color: #eee;
  width: 100vw;
  max-width: 100vw;
  font-family: 'circular-book';

  &.loaded {
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

*,
*:focus {
  border: none !important;
  outline: none !important;
}
