body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.site-container {
  display: inline-flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.image-container {
  width: 50vw;
  height: 100vh;
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
    height: 35vh;
    overflow: hidden;
  }
}

.text-container {
  width: 50vw;
  height: 100vh;
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
    height: 65vh;
  }
}




.image-container-logo {
  display: flex;
  position: fixed;
  z-index: 900000;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  left: 0;
  top: 0;


  svg {
    fill: white;
    width: 80px;
    height: 80px;
    transition: all ease-in-out 0.25s;

    &:hover {
      width: 90px;
      height: 90px;
      fill: #CE8464;
      transition: all ease-in-out 0.25s;
    }
  }
}

.image-container {
  background-color: #CE8464;
}

.slider-slide {
  width: 50vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  @media (max-width: 768px) {
    width: 100vw;
    height: 50vh;
  }
}

.text-container {
  color: #CE8464;
}

// Hack the ting
.slick-track {
  display: inline-flex !important;
}



.tb-player {
  z-index: 999999;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
