.info-container,
.subscribe-container,
.privacy-container {
  color: black;
  background-color: #CE8464;

  left: 0;
  right: 0;
  bottom: 0;
  top: 100vh;
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: scroll;

  transition: all 0.25s ease-in-out;

  &.hidden-content {
    top: 0 !important;
    transition: all 0.25s ease-in-out;
  }

  @media (max-width: 768px) {
    position: fixed;
    z-index: 999999999998;
  }

  p {
    line-height: 150% !important;
    a {
      border-bottom: 2px solid !important;
    }
  }
}

.privacy-container {
  height: auto !important;

  @media (max-width: 768px) {
    position: fixed;
    z-index: 999999999999;
  }
}





.content-relative-wrapper {
  position: relative;
  height: 100vh;
}

.circ-black {
  font-family: "circular-black";
}

.bio {
  font-family: "circular-book";
  font-size: 1.25em;
  line-height: 135%;
  padding: 20px;

  p {
    margin-bottom: 20px;
    max-width: 700px;
    width: 85%;
  }
}

.close-link {
  top: 20px;
  right: 20px;
  position: absolute;

  svg {
    height: 50px;
    width: 50px;
    fill: black;
    transition: all 0.25s ease-in-out;

    &:hover {
      fill: white;
      transition: all 0.25s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    bottom: 100px;
  }
}


.tb-mailing-list {
  // margin: 50px auto;
  padding-left: 20px;
  padding-bottom: 200px;

  p {
    margin-bottom: 20px;
    max-width: 700px;
    width: 85%;
  }

  .button {
    padding: 10px 20px;
    margin-top: 40px;
    font-size: 1.5em;
    transition: all 0.25s ease-in-out;
    border: solid black 2px !important;
    font-family: "circular-book" !important;
    background-color: transparent !important;

    &:hover {
      color: white;
      background-color: black !important;
      transition: all 0.25s ease-in-out;
    }
  }
}


.info-heading {
  font-size: 1.25em;
  margin-bottom: 50px !important;
  font-family: "circular-bold" !important;
}

.mc-field-group {
  margin-bottom: 20px;
}

.tb-form-input {
  width: 70%;
  color: black;
  font-size: 1.25em;
  margin-bottom: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border-bottom: solid black 2px !important;
}

#mc_embed_signup div.mce_inline_error {
  background-color: transparent !important;
  font-family: "circular-book" !important;
  font-weight: lighter !important;
  padding-left: 0 !important;
  color: white !important;
  font-size: 15px;
}

input:-internal-autofill-selected {
  background-color: transparent !important;;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "circular-book";
  color: black;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "circular-book";
  color: black;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "circular-book";
  color: black;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "circular-book";
  color: black;
}


.secondary-block {
  margin-top: 50px;
  margin-bottom: 50px;
}

.content__gdpr {
  .sub-head {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  fieldset {
    label {
      display: block;
      width: 100%;
    }
  }
}


.contact {
  margin-bottom: 100px;
  padding-left: 20px;
  font-size: 15px;

  .contact-section {
    margin-bottom: 30px;
  }

  h3 {
    font-family: "circular-bold";
    margin-bottom: 5px;
  }

  p {
    line-height: 125% !important;
  }

  .link-wrapper {
    width: 100%;
    margin-bottom: 10px;
    a {
      border-bottom: 2px solid !important;
    }
  }
}

.credit {
  margin-bottom: 125px;
  padding-left: 20px;
  font-size: 15px;
}
